/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faPlay, faPenToSquare, faCircleXmark, faChevronRight, faMagnifyingGlass, faPlus, faSortUp,faSortDown, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, deleteContactRequest, getContactRequest } from '../../../redux/modules/contacts/contactSlice';
import { RootState } from '../../../redux/rootReducer';
import { toast } from 'react-toastify';
import { Contact, Filter, FilterOperator, FilterOption, PermissionObject } from '../../../redux/modules/auth/types';
import { Button, Col, Form, Modal,OverlayTrigger,Row,Table, Tooltip} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addListParams } from '../../../redux/modules/lists/types';
import { addListsRequest, clearListData } from '../../../redux/modules/lists/listsSlice';
import XDeleteModal from '../../../component/modal/XDeleteModal';
import useSidebar from '../../../hooks/useSidebar';
import useList from '../../../hooks/useList';
import Select from 'react-select';
import PaginationNav from '../../../component/pagination/pagination';
import XButton from '../../../component/button/XButton';
import { XModal } from '../../../component';
import { addContactRequest } from '../../../redux/modules/contacts/contactSlice';
import XLoader from '../../../component/loader/XLoader';
import { toaster } from '../../../utils/toast';
import { getPredefinedFieldRequest } from '../../../redux/modules/settings/settingsSlice';
import { downloadCsv } from "../../../utils/helper";
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import XConfirm from '../../../component/confirm/XConfirm';
import { CampaignType } from '../../../utils/enums';

interface ContactsProps {
  permission?: PermissionObject
}

const AllContacts: React.FC<ContactsProps> = (props) => {
  const { permission } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorRes ,successRes ,listRes} = useSelector((state: RootState) => state.lists);
  const { contactlist, loading: contactLoading, addedContact, deletedContact, error} = useSelector((state: RootState) => state.contactlist);
  const [show, setShow] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [showCreateListModel, setShowCreateListModel] = useState(false);
  const [showExistingListModel, setShowExistingListModel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    limit: 10,
    filters: [] as Filter[],
  });
  const initialFilter: Filter = {
    selectedFilter: 'Select',
    selectedOperator: 'Select',
    inputValue: '',
    logicalOperator: null,
  };
  const [filters, setFilters] = useState<Filter[]>([initialFilter]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedListId, setSelectedListId] = useState<any>([]);
  const [existingContacts, setExistingContacts] = useState<any>([]);
  const [createOrExitsList, setCreateOrExitsList] = useState<string>('CREATE');
  const [selectedUser, setSelectedUser] = useState<Contact| null>(null);
  const { onShowSidebar } = useSidebar();
  const [selectAll, setSelectAll] = useState<any>(false);
  const { listMap } = useList()
  const [showUploadContactsModel, setShowUploadContactsModel] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const { predefinedFieldList } = useSelector((state: RootState) => state.settings);
  const { postData, isLoading } = useAxios();
  const [unsubscribeModal, setUnsubscribeModal] = useState<boolean>(false);
  const [selectedUnsubscribe, setSelectedUnsubscribe] = useState<any>({
    campaignType: '',
    contactId: ''
  });
  const [contactSubscribeStatus, setContactSubscribeStatus] = useState<boolean>(false)
  const { smsFeature } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getPredefinedFieldRequest());
    dispatch(getContactRequest(queryParams));
    const totalContacts = contactlist?.totalCount;
    const totalPages = Math.ceil(totalContacts / queryParams?.limit);
    setTotalPages(totalPages);
  }, [queryParams, contactlist?.totalCount])

    useEffect(() => {
      if (deletedContact && deletedContact.success === true) {
        toast(deletedContact.message);
        dispatch(getContactRequest(queryParams));
      }
      else if (error && error.success === false) {
        toaster({success: false, message: error.message});
      }
      dispatch(clearData());
    }, [error, deletedContact,queryParams])

    useEffect(() => {
      if (addedContact && addedContact.success === true) {
        toast(addedContact.message);
        setShowUploadContactsModel(false);
        dispatch(getContactRequest(queryParams));
      }
      else if (error && error.success === false) {
        toaster({success: false, message: error.message});
      }
      dispatch(clearData());
    }, [error, addedContact])

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleSearchChange = (searchValue: string): void => {
    setQueryParams((prevParams) => ({ ...prevParams, page: 1, search: searchValue }));

  };

  const handleSortClick = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedContacts = [...(contactlist?.contactList || [])].sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    }
    return 0;
  });

  const handleEditClick =(contactId: string)=>{
    const encryptedContactId = contactId
  navigate(`/org/edit-contact/${encodeURIComponent(encryptedContactId)}`);
  }

  const handleAdvanceSearchShow = () => {
    setAdvanceSearch(true)
  };
  const handleAdvanceSearchClose=()=>{
    resetFilters();
    setAdvanceSearch(false);
  }

  const resetFilters = () => {
    setFilters([initialFilter]);
  };

  const handleContactDetailClick = (contactId: string) => {
    const encryptedContactId = contactId
    navigate(`/org/contact-detail/${encodeURIComponent(encryptedContactId)}`)
  }

  const handleFilterChange = (
    index: number,
    property: keyof Filter,
    value: string | FilterOption | FilterOperator | 'AND' | 'OR'
  ) => {
    const updatedFilters = [...filters];
      if (index === 0) {
      updatedFilters[index].logicalOperator = null;
    } else {
      updatedFilters[index].logicalOperator = updatedFilters[index - 1].logicalOperator;
    }
    updatedFilters[index][property] = value as never;
    setFilters(updatedFilters);
    // setQueryParams((prevParams) => ({...prevParams, filters: updatedFilters }));
  };
  
  const addFilter = () => {
    setFilters((prevFilters) => [
      ...prevFilters,
      {
        selectedFilter: 'Select',
    selectedOperator: 'Select',
    inputValue: '',
    logicalOperator: 'AND',
      },
    ]);
  };

  
  const removeFilter = (index: number) => {
    const updatedFilters = [...filters];
    if (index > 0 && updatedFilters[index].logicalOperator != null) {
      updatedFilters[index - 1].logicalOperator = updatedFilters[index].logicalOperator as 'AND' | 'OR';
    }
    updatedFilters[index].logicalOperator = null;//added
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
    setQueryParams((prevParams) => ({...prevParams, filters: updatedFilters }));
  };
  const handleAdvanceSearch = () => {
    setQueryParams((prevParams) => ({...prevParams, filters: filters }));
    dispatch(getContactRequest(queryParams)); 
    handleAdvanceSearchClose();
  };

  const handleCheckboxChange = (id: string) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = prevCheckedItems.includes(id)
        ? prevCheckedItems.filter((item) => item !== id)
        : [...prevCheckedItems, id];
  
      const allContactIds = sortedContacts.map((contact) => contact.id);
      const isAllChecked = allContactIds.every((contactId) => updatedCheckedItems.includes(contactId));
      setSelectAll(isAllChecked || false);
      return updatedCheckedItems;
    });
  };
  const handleList = (type: string) => {
    setCreateOrExitsList(type)
    type === 'CREATE' ? setShowCreateListModel(true) : setShowExistingListModel(true)
    const modifiedArray = checkedItems.map((value, index) => ({
      id: index,
      value: value,
    }));
    setSelectedOptions(modifiedArray)
  };

  const validationSchema = Yup.object({
    listName: Yup.string().trim().required('List name is required').test(
      (value) => value.trim() !== ''
    ),
  });

  const formik = useFormik<addListParams>({
    initialValues: {
      listName: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: addListParams) => {
      if(createOrExitsList === 'CREATE'){
        const payload={
          ...values,
          selectedOptions,
          update_id:'',
        }
        dispatch(addListsRequest(payload));
      }  
    },
  });

  const existingListFormik = useFormik<any>({
    initialValues: {
      listName: ''
    },
    onSubmit: async (values: any) => {
      const newContacts = selectedOptions.filter((option:any) => (
        !existingContacts.some((contact:any) => contact.value === option.value)
      ));
      const updatedContacts = [...existingContacts, ...newContacts];
      if(createOrExitsList === 'EXISTING'){
          const payload={
          ...values,
          selectedOptions: updatedContacts,
          update_id: selectedListId,
          addToExitingList:true
        }
        dispatch(addListsRequest(payload));
      }
    },
  });

  const uploadContactsFormik = useFormik<any>({
    initialValues: {},
    onSubmit: async (values: any) => {
        if (csvFile) {
          const formData = new FormData();
          formData.append('csvFile', csvFile);
          dispatch(addContactRequest({ data: formData }));
          setCsvFile(null)
        }
      }
  });

  useEffect(() => {
    if(successRes === true) {
      setShowCreateListModel(false);
      setShowExistingListModel(false);
      toast(listRes.message)
      formik.resetForm();
    }
    errorRes && toaster({success: false, message: errorRes.message});
    dispatch(clearListData());
  }, [successRes ,errorRes])

  const handleDeleteContact = (contactId: string) => {
    dispatch(deleteContactRequest(contactId));
    
};
  const handleDeleteIconClick = (contact:any) => {
    setSelectedUser(contact);
    setShowDeleteModal(true);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      const allContactIds = sortedContacts.map((contact) => contact.id);
      setCheckedItems(allContactIds);
    }
    setSelectAll(!selectAll);
  };

  const handleFileChange = (input: HTMLInputElement) => {
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if(file.size <= 3145728) {
        setCsvFile(file);
      }
    }
 };

 const handleAddContactType = (value:any) => {
  if(value === 1) {
    navigate('/org/addContact')
  } else {
    setShowUploadContactsModel(true)
    setShow(false)
  }
 }

 const downloadCsvFormat = () => {
  downloadCsv(predefinedFieldList)
 };

 const onToggleChange = async (campaignType: string, contactId: string) => {
  setSelectedUnsubscribe({campaignType, contactId})
  setUnsubscribeModal(true)
};

const onUnsubscribeSubmit = async () => {
  const response = await postData(API_ENDPOINTS.TOGGLE_UNSUBSCRIBE, selectedUnsubscribe);
  const { message, success } = response;
  toaster({message, success})
  setUnsubscribeModal(false)
  dispatch(getContactRequest(queryParams));
}

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Contacts</h2>
        <div className="rt-btns">
          {(permission?.edit) && (
            <Button
              variant="primary"
              type="submit"
              className="btn-theme"
              onClick={() => {
                setShow(true)
              }} 
            >Add Contact
            </Button>
          )}
          <Button
              variant="primary"
              type="submit"
              className="btn-theme"
              onClick={handleAdvanceSearchShow}
            >Advanced Search
            </Button>
          {checkedItems && checkedItems.length > 0 && <>
          <Button
                variant="primary"
                type="submit"
                className="btn-theme"
                onClick={() => handleList("CREATE")}
              >Create New List
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="btn-theme"
                onClick={() => handleList('EXISTING')}
              >Add To Existing List
              </Button>
          </>}
        </div>
      </div>
      <div className="filter-bar d-flex justify-content-between align-items-center">
        <div className='position-relative search-bar'>
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={queryParams.search}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            {checkedItems.length > 0 && <p>{checkedItems.length} record(s) selected</p>}
          </div>

      <div className="table-listing contact-list all-contacts position-relative">
        <Table responsive>
          <thead>
            <tr>
              <th className='d-flex'>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check 
                    type="checkbox" 
                    checked={selectAll} 
                    onChange={handleSelectAllChange}
                    ref={(input: any) => {
                      if (input) {
                        input.indeterminate = checkedItems.length > 0 && checkedItems.length < sortedContacts.length;
                      }
                    }}
                  />
                </Form.Group>
                Email Address
              </th>
              <th onClick={() => handleSortClick('firstname')}>First Name {sortColumn === "firstname" && <FontAwesomeIcon
               icon={sortOrder === "asc" ? faSortUp : faSortDown}/>}</th>
              <th onClick={() => handleSortClick('lastname')}>Last Name {sortColumn === "lastname" && <FontAwesomeIcon 
              icon= {sortOrder === "asc" ? faSortUp : faSortDown}/>}</th>
              <th>Address </th>
              <th>Phone Number </th>
              <th>DOB </th>
              <th>Tags </th>
              <th>Email Unsubscribe </th>
              {smsFeature && <th>SMS Unsubscribe</th>}
              <th>Actions </th>
            </tr>
          </thead>
            
          <tbody>
            {!contactLoading && sortedContacts?.length === 0 &&
              <tr>
                <td colSpan={9}>
                  <p className='text-center no-records'>No contact found.</p>
                </td>
              </tr>
            }
            {!contactLoading && sortedContacts && sortedContacts?.map((contact: Contact) => (
              <tr key={contact.id}>
                <td className='d-flex'>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" key={contact.id}
                     checked={checkedItems.includes(contact.id)}
                     onChange={() => handleCheckboxChange(contact.id)}
                    />
                  </Form.Group>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-bottom">{contact.email}</Tooltip>}
                  >
                    <span className="d-inline-block text-truncate cursor-pointer" style={{ maxWidth: "150px" }}>
                      {contact.email}
                    </span>
                  </OverlayTrigger>
                  <FontAwesomeIcon icon={faChevronRight} className='ms-2 cursor-pointer' 
                    onClick={() => handleContactDetailClick(contact.id)}
                    />
                </td>
                <td>{contact.firstname}</td>
                <td>{contact.lastname}</td>
                <td>{contact.address}</td>
                <td>{contact.phone_number}</td>
                <td>{contact.DOB !== null ? new Date(contact.DOB).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) : ''}</td>
                <td>
               {contact && contact?.tags?.map((tag, index) => (
                    <span key={index} className={tag?.name && 'tag-name'}>{tag?.name}</span>
                  ))}
                </td>
                <td>
                  <Form.Check
                    name={CampaignType.EMAIL}
                    type="switch"
                    id="custom-switch"
                    checked={contact.unsubscribe}
                    onChange={() => {
                      onToggleChange(CampaignType.EMAIL, contact.id)
                      setContactSubscribeStatus(contact.unsubscribe)
                    }}
                  />
                </td>
                {smsFeature &&
                  <td>
                    <Form.Check
                      name={CampaignType.SMS}
                      type="switch"
                      id="custom-switch"
                      checked={contact.sms_unsubscribe}
                      onChange={() => {
                        onToggleChange(CampaignType.SMS, contact.id)
                        setContactSubscribeStatus(contact.sms_unsubscribe)
                      }}
                    />
                  </td>
                }
                <td className='actions'>
                  {(permission?.delete) && (
                    <span><FontAwesomeIcon icon={faCircleXmark} 
                    onClick={() => handleDeleteIconClick(contact)}
                    /></span>
                  )}
                  {(permission?.edit) && (        
                    <span><FontAwesomeIcon icon={faPenToSquare} onClick={() => handleEditClick(contact.id)}/></span>
                  )}
                  </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {contactLoading && <XLoader />}
        <XConfirm
          message={`Are you sure you want to ${contactSubscribeStatus ? "subscribe" : "unsubscribe"} ${selectedUnsubscribe.campaignType}?`}
          show={unsubscribeModal}
          onCancel={() => setUnsubscribeModal(false)}
          onConfirm={onUnsubscribeSubmit}
          isLoading={isLoading}
        />
        <XDeleteModal
                  show={showDeleteModal}
                  handleClose={() => setShowDeleteModal(false)}
                  title="Delete Contact"
                  body={`Are you sure you want to delete the Contact?`}
                  onConfirm={() => {
                    if (selectedUser) {
                      handleDeleteContact(selectedUser.id);
                    }    setShowDeleteModal(false);
                  }}
                onCancel={() => setShowDeleteModal(false)} 
      />
      </div>
      {!contactLoading && sortedContacts?.length > 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
      <Modal show={advanceSearch} onHide={handleAdvanceSearchClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Advanced Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body className='advance-search'>
                <div className="content">
                  <div className="box mb-3">
                  {filters.map((filter, index) => (
                    <div className="item" key={index}>
                        {index > 0 && (
                      <div className="choose-option d-flex align-items-center justify-content-between">  
                        <span className="text">
                          <span 
                          className={`ml-2 ${filter.logicalOperator === 'AND' ? 'active' : ''}`}
                          onClick={() => handleFilterChange(index, 'logicalOperator', 'AND')}                        
                          >AND</span>
                          <span 
                          className={`ml-2 ${filter.logicalOperator === 'OR' ? 'active' : ''}`}
                          onClick={() => handleFilterChange(index, 'logicalOperator', 'OR')}
                          >OR</span>
                        </span>
                        <span onClick={() => removeFilter(index)}><FontAwesomeIcon icon={faCircleXmark} /></span> 
                      </div>
                        )}
                      <div className="">
                        <Row>
                          <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                              <Form.Select aria-label="Default select example"
                              value={filter.selectedFilter}
                              onChange={(e) => handleFilterChange(index, 'selectedFilter', e.target.value)}
                              >
                                <option>Select</option>
                                <option value="Tags">Tags</option>
                                <option value="Email">Email</option>
                                <option value="FirstName">First Name</option>
                                <option value="Address">Address</option> 
                              </Form.Select>
                            </Form.Group>             
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                              <Form.Select aria-label="Default select example"
                                value={filter.selectedOperator}
                                onChange={(e) => handleFilterChange(index, 'selectedOperator', e.target.value)}
                              >
                                <option>Select</option>
                                <option value="Contains">contains</option>
                                <option value="DoesNotContain">does not contain</option>
                              </Form.Select>     
                            </Form.Group>
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Form.Group className="form-group" controlId="formBasicEmail">
                              <Form.Control type="text" placeholder="Enter" 
                              value={filter.inputValue}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFilterChange(index, 'inputValue', e.target.value)
                              }
                              />
                            </Form.Group>                            
                          </Col>
                        </Row>
                      </div>
                    </div>
                    ))}
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme"
                      onClick={addFilter}
                    ><FontAwesomeIcon icon={faPlus} className='ms-0' /> Add Filter
                    </Button>                   
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className='justify-content-between'>
                <Button variant="primary" className='btn-theme btn-border' onClick={handleAdvanceSearchClose}>
                  Discard
                </Button>
                <Button variant="primary" type="submit" className='btn-theme' onClick={handleAdvanceSearch}>
                  Search
                </Button>
              </Modal.Footer>
      </Modal>
      <Modal show={showCreateListModel} onHide={() => setShowCreateListModel(false)} centered>
      <Form  noValidate onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create New List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>List Name</Form.Label>
                <Form.Control type="text" placeholder="Enter"
                  name="listName"
                  value={formik.values.listName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.listName && !!formik.errors.listName}
                  required
                />
                <Form.Control.Feedback type="invalid">{formik.errors.listName}</Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Button
                variant="primary"
                type="submit"
                className="btn-theme"
              >
              Save & Continue
                <FontAwesomeIcon icon={faPlay} />
              </Button>
            </Modal.Footer>
          </Form>
      </Modal>
      
      <Modal
        show={showExistingListModel}
        onHide={() => setShowExistingListModel(false)}
        centered
      >
        <Form noValidate onSubmit={existingListFormik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add To Existing List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Select List</Form.Label>
                <Select
                  options={Object.values(listMap)}
                  closeMenuOnSelect={true}
                  onChange={(selected:any) => {
                    if (selected && selected.name) {
                      existingListFormik.setFieldValue('listName', selected.name);
                      setSelectedListId(selected.id)
                      setExistingContacts(selected?.contacts)
                    }
                  }}
                />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button variant="primary" type="submit" className="btn-theme">
              Save & Continue
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showUploadContactsModel}
        onHide={() => setShowUploadContactsModel(false)}
        centered
      >
        <Form noValidate onSubmit={uploadContactsFormik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Contacts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <div className='d-flex justify-content-between align-items-center'>
                <Form.Label>Import Contacts </Form.Label>
                <Button className="csv-download-btn-style" onClick={downloadCsvFormat}>
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    onClick={downloadCsvFormat}
                    className='me-2'
                  />
                  Download CSV Format 
                </Button>
              </div>
              <Form.Control 
                type="file" 
                onChange={(e) => handleFileChange(e.target as HTMLInputElement)} 
                accept=".csv"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <XButton
              variant="primary"
              type="submit"
              buttonText={<> Save & Continue<FontAwesomeIcon icon={faPlay}/> </>}
              loading={contactLoading}
            /> 
          </Modal.Footer>
        </Form>
      </Modal>
      <XModal
        title='Add Contacts'
        body={
          <Form.Group className='d-flex align-items-center custom-radio-btns gap-3'>
            <Form.Check
                type="radio"
                label="Add Single Contact"
                className="d-flex align-items-center"
                name="add_contact_type"
                value="1"
                id="add_single_contact_radio"
                onChange={() => handleAddContactType(1)}
            />
            <Form.Check
                type="radio"
                label="Import Bulk Contacts"
                className="d-flex align-items-center"
                name="add_contact_type"
                value="2"
                id="import_bulk_contacts_radio"
                onChange={() => handleAddContactType(2)}
            />
          </Form.Group>
        }
        show={show}
        handleClose={() => setShow(false)}
      />
    </div>
  )
}

export default AllContacts;