import { useFormik } from 'formik';
import { Form, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { XModal } from '../../component';
import { API_ENDPOINTS } from "../../constants/api";
import useAxios from "../../hooks/useAxios";
import { JourneyFormValues } from '../../redux/modules/auth/types';
// import { campaignTypeOptions } from '../../utils/options';
import { toaster } from '../../utils/toast';
import { CampaignType, TimeUnit } from '../../utils/enums';
import useDomains from '../../hooks/useDomains';
import { useEffect } from 'react';
import { decodeMilliseconds } from '../../utils/helper';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Journey name is required'),
  type: Yup.string().required('Journey type is required'),
  fromId: Yup.string().required('Email domain is required'),
  delayValue: Yup.number().test({
    name: 'isGreaterThanZero',
    exclusive: true,
    message: 'Delay Value must be greater than 0',
    test: function (value) {
      const isTimeDelay = this.parent.isTimeDelay;
      if (isTimeDelay === 1) {
        if (typeof value === 'number') {
          return value > 0;
        }
        return false;
      }
      return true;
    }
  }).required('Delay Value is required'),
  delayUnit: Yup.string().test({
    name: 'isRequiredWhenTimeDelayIsSet',
    exclusive: true,
    message: 'Delay Unit is required',
    test: function (value) {
      const isTimeDelay = this.parent.isTimeDelay;
      if (isTimeDelay === 1) {
        return value !== undefined && value !== '';
      }
      return true;
    },
  }),
});


type CreateJourneyProp = {
  show: boolean,
  handelToggle: () => void
  journeyEditData?: any
}

const CreateJourney = (props: CreateJourneyProp) => {
  const { show, handelToggle, journeyEditData } = props
  const { postData, isLoading } = useAxios();
  const { domains } = useDomains()
  const navigate = useNavigate();
  const isJourneyCompleted = journeyEditData?.status === "completed"
  const title = journeyEditData ? (isJourneyCompleted ? "Detail journey" : "Update journey") : "Set up the new journey"
  const { value: timeDelayValue, unit: timeDelayUnit } = decodeMilliseconds(journeyEditData?.time_delay);


  const formik = useFormik<JourneyFormValues>({
    initialValues: {
      name: journeyEditData?.name ?? "",
      type: journeyEditData?.type ?? CampaignType.EMAIL,
      status: journeyEditData?.status ?? "Draft",
      fromId: journeyEditData?.domain?.id ?? '',
      isTimeDelay: timeDelayValue ? 1: 0,
      delayValue: timeDelayValue ?? 0,
      delayUnit: timeDelayUnit ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: JourneyFormValues) => {
      const payload = {
        name: values.name,
        type: values.type,
        status: values.status,
        fromId: +values.fromId,
        isTimeDelay: values.isTimeDelay,
        ...(journeyEditData?.id && { journeyId: journeyEditData.id }),
        ...(!!values.isTimeDelay && {
          delayValue: values.delayValue,
          delayUnit: values.delayUnit,
        })
      }
      const response = isJourneyCompleted ? {} : await postData(API_ENDPOINTS.CREATE_JOURNEY, payload);
      const { success, data, message } = response
      if (success || isJourneyCompleted) {
        formik.resetForm();
        navigate('/org/journey/journeyBuilder', {
          state: {
            journeyId: data?.id,
            journeyName: payload?.name,
            journeyStatus: journeyEditData?.status,
            journeyEditData,
            journeyPayload: {...payload, journeyId: data?.id ?? journeyEditData?.id}
          }
        })
      } else {
        toaster({ message, success })
      }
    },
  });

  useEffect(() => {
  }, [formik.errors])


  return (
    <div>
      <XModal show={show}
        handleClose={handelToggle}
        title={title}
        confirmText={isLoading ? "Loading..." : `Start Building`}
        handleConfirm={formik.handleSubmit}
        disabled={isLoading}
        body={<Form>
          <Form.Group className="form-group">
            <Form.Label>Enter the journey name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Enter"
              {...formik.getFieldProps('name')}
              disabled={isJourneyCompleted}
              isInvalid={formik.touched.name && !!formik.errors.name}
            />
            {formik.errors.name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="formBasicEmail">
            <Form.Label>From Email Domain</Form.Label>
            <Form.Select
              name='fromId'
              aria-label="Default select example"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fromId}
              isInvalid={formik.touched.fromId && !!formik.errors.fromId}
              disabled={isJourneyCompleted}
            >
              <option value="">Choose</option>
              {domains.map((v, index) => {
                return (
                  <option value={v.value} key={index}>{v.text}</option>
                )
              })}
            </Form.Select>
            {formik.errors.fromId && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.fromId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicRadiobox">
            <Form.Label>Time Delay</Form.Label>
            <div className='d-flex align-items-center custom-radio-btns gap-3'>
              <Form.Check
                name="isTimeDelay"
                type="radio"
                label="Send Immediately"
                className="d-flex align-items-center"
                checked={formik.values.isTimeDelay === 0}
                onChange={() => {
                  formik.setFieldValue('isTimeDelay', 0)
                }}

                value={0}
                id="send_immediately_radio"
                disabled={isJourneyCompleted}
              />
              <Form.Check
                name="isTimeDelay"
                type="radio"
                label="Choose Time Delay"
                className="d-flex align-items-center"
                checked={!!formik.values.isTimeDelay}
                onChange={() => {
                  formik.setFieldValue('isTimeDelay', 1)
                }}

                value={1}
                id="choose_time_delay_radio"
                disabled={isJourneyCompleted}
              />
            </div>
          </Form.Group>
          {!!formik.values.isTimeDelay && <Form.Group className="form-group mt-3" controlId="formBasicEmail">
            <div className="mb-3 d-flex gap-3 align-items-center">
              <Form.Label style={{ flex: 'none' }}>Delay for</Form.Label>
              <div>
                <FormControl
                  name="delayValue"
                  type="number"
                  placeholder="Enter delay value"
                  value={+formik.values.delayValue}
                  onChange={(e) => {
                    const targetValue = Number(e.target.value);
                    if (targetValue >= 0) {
                      formik.handleChange(e);
                    }
                  }}
                  isInvalid={formik?.touched?.delayValue && !!formik?.errors?.delayValue}
                  disabled={isJourneyCompleted}
                />
                {formik.errors.delayValue && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.delayValue}
                  </Form.Control.Feedback>
                )}
              </div>
              <div>
                <Form.Select
                  name="delayUnit"
                  value={formik.values.delayUnit}
                  onChange={formik.handleChange}
                  isInvalid={formik?.touched?.delayUnit && !!formik?.errors?.delayUnit}
                  disabled={isJourneyCompleted}
                >
                  <option value="">Select</option>
                  <option value={TimeUnit.MINUTES}>Minutes</option>
                  <option value={TimeUnit.HOURS}>Hours</option>
                  <option value={TimeUnit.DAYS}>Days</option>
                </Form.Select>
                {formik.errors.delayUnit && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.delayUnit}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>
          </Form.Group>}

          {/* <Form.Group
            className="form-group"
          >
            <Form.Label>Select your journey type</Form.Label>
            <Form.Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              disabled={isJourneyCompleted}
              isInvalid={formik.touched.type && !!formik.errors.type}
            >
              <option value="">Select</option>
              {campaignTypeOptions.map((v) => (
                <option key={v.key} value={v.value}>{v.text}</option>
              ))}
            </Form.Select>
            {formik.errors.type && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.type}
              </Form.Control.Feedback>
            )}
          </Form.Group> */}
        </Form>} />
    </div>
  )
}

export default CreateJourney